import gql from "graphql-tag";

export const typeDefs = gql`
  type SiteProps {
    locale: String!
  }
`;

export const defaults = {
  site: {
    __typename: "SiteProps",
    locale: "zh"
  }
};

export const resolvers = {
  Mutation: {
    setLocale: (_, { locale }, { cache }) => {
      cache.writeData({
        data: {
          site: {
            __typename: "SiteProps",
            locale
          }
        }
      });
      return null;
    }
  }
};

export default {
  typeDefs,
  defaults,
  resolvers
};
