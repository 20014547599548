import React, { Component, Fragment } from "react";
import { ApolloProvider, Query } from "react-apollo";
import ApolloClient, { gql } from "apollo-boost";
import { BrowserRouter } from "react-router-dom";
import menuState from "./state/menu";
import siteState from "./state/site";
import { InMemoryCache } from "apollo-cache-inmemory";
import { IntlProvider } from "react-intl";
import renderRouters from "./utils/renderRouter";
import routerConfig from "./config/router";
import { merge } from "lodash";
import initLocale from "./config/initLocale";
import Script from "react-load-script";
import createReactContext from "create-react-context";
import { getToken } from "./utils/localStorage";
import { LocaleProvider } from "antd";
import antd_En from "antd/lib/locale-provider/en_US";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import {getBaseUrl} from "./utils/baseUrl";
import "./css/common.css";

const cache = new InMemoryCache({
  dataIdFromObject: object => object._id
});

const { messages } = initLocale();

const client = new ApolloClient({
  uri: getBaseUrl(process.env.REACT_APP_BASE_URL),
  cache,
  request: operation => {
    operation.setContext(context => ({
      headers: {
        ...createReactContext.headers,
        authorization: getToken() || ""
      }
    }));
  },
  clientState: merge(menuState, siteState)
});

const GET_LOCALE = gql`
  query {
    site @client {
      locale
    }
  }
`;

const GOOGLE_MAP_API_US =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyASvRWORWQ9Dl2GK4Q22_ALIgLIa3FFYrc&libraries=places";
const GOOGLE_MAP_API_CN =
  "https://ditu.google.cn/maps/api/js?key=AIzaSyASvRWORWQ9Dl2GK4Q22_ALIgLIa3FFYrc&libraries=places";

  class LoadGoogleMapScript extends Component {
    render() {
      const host = window.location.hostname;
      const GOOGLE_MAP_API = host.includes("lettopia.cn") ? GOOGLE_MAP_API_CN : GOOGLE_MAP_API_US;
      return (
        <Fragment>
          <Script url={GOOGLE_MAP_API} />
        </Fragment>
      );
  }
}

class App extends Component {
  render() {
    console.log(process.env, "env")
    return (
      <div className="App">
        <LoadGoogleMapScript />
        <BrowserRouter>
          <ApolloProvider client={client}>
            <Query query={GET_LOCALE}>
              {({
                data: {
                  site: { locale }
                }
              }) => {
                return (
                  <LocaleProvider locale={locale === "zh" ? zh_CN : antd_En}>
                    <IntlProvider locale={locale} messages={messages[locale]}>
                      {renderRouters(routerConfig)}
                    </IntlProvider>
                  </LocaleProvider>
                );
              }}
            </Query>
          </ApolloProvider>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
