import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { getToken } from "../utils/localStorage";
const RouteInstanceMap = {
  get(key) {
    return key._routeInternalComponent;
  },
  has(key) {
    return key._routeInternalComponent !== undefined;
  },
  set(key, value) {
    key._routeInternalComponent = value;
  }
};

// Support pass props from layout to child routes
const RouteWithProps = ({ path, exact, strict, render, location, ...rest }) => (
  <Route
    path={path}
    exact={exact}
    strict={strict}
    location={location}
    render={props => render({ ...props, ...rest })}
  />
);

function withRoutes(route) {
  if (RouteInstanceMap.has(route)) {
    return RouteInstanceMap.get(route);
  }

  const { Routes } = route;
  let len = Routes.length - 1;
  let Component = args => {
    const { render, ...props } = args;
    return render(props);
  };
  while (len >= 0) {
    const AuthRoute = Routes[len];
    const OldComponent = Component;
    Component = props => (
      <AuthRoute {...props}>
        <OldComponent {...props} />
      </AuthRoute>
    );
    len -= 1;
  }

  const ret = args => {
    const { render, ...rest } = args;
    return (
      <RouteWithProps
        {...rest}
        render={props => {
          return <Component {...props} route={route} render={render} />;
        }}
      />
    );
  };
  RouteInstanceMap.set(route, ret);
  return ret;
}

export function renderRoutes(routes, extraProps = {}, switchProps = {}) {
  return routes ? (
    <Switch {...switchProps}>
      {routes.map((route, i) => {
        if (route.auth) {
          const token = getToken();
          if (!token) {
            return <Redirect
            key={route.key || i}
            from={route.path}
            to={'/login'}
            exact={route.exact}
            strict={route.strict}
          />
          }
        }
        if (route.redirect) {
          return (
            <Redirect
              key={route.key || i}
              from={route.path}
              to={route.redirect}
              exact={route.exact}
              strict={route.strict}
            />
          );
        }
        const RouteRoute = route.Routes ? withRoutes(route) : RouteWithProps;
        return (
          <RouteRoute
            key={route.key || i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            search = {route.search}
            render={props => {
              const childRoutes = renderRoutes(
                route.routes,
                {},
                {
                  location: props.location
                }
              );
              if (route.component) {
                const compatProps = {
                  ...props,
                  ...extraProps
                };
                return (
                  <route.component {...compatProps} route={route}>
                    {childRoutes}
                  </route.component>
                );
              } else {
                return childRoutes;
              }
            }}
          />
        );
      })}
    </Switch>
  ) : null;
}

const loadCompoentsLazy = configs => {
  if (!configs) {
    return null;
  }
  if (Array.isArray(configs)) {
    return configs.map(config => loadCompoentsLazy(config));
  }
  configs = { ...configs };
  if (configs.component) {
    const LazyComponent = React.lazy(configs.component);
    configs.component = props => (
      <Suspense fallback={<div></div>}>
        <LazyComponent {...props} />
      </Suspense>
    );
  }

  if (configs.routes) {
    configs.routes = loadCompoentsLazy(configs.routes);
  }

  return configs;
};

export default function renderRoutesWithLazyLoad(routeConfig) {
  const routersWithLazyCompoents = loadCompoentsLazy(routeConfig);
  return renderRoutes(routersWithLazyCompoents);
}
