import gql from "graphql-tag";

export const typeDefs = gql`
  type MenuProps {
    collapsed: Boolean!
  }
`;

export const defaults = {
  menu: {
    __typename: "MenuProps",
    collapsed: false
  }
};

export const resolvers = {
  Mutation: {
    setMenuCollapsed: (_, { collapsed }, { cache }) => {
      cache.writeData({
        data: {
          menu: {
            __typename: "MenuProps",
            collapsed
          }
        }
      });
      return null;
    }
  }
};

export default {
  typeDefs,
  defaults,
  resolvers
};
