function getBaseUrl(param) {
    if (param === "local") {
        return "http://localhost:4000/graphql"
    } else if (param === "gcloudtest" ) {
        return "https://gcloud-test-api-seller.lettopia.com/graphql"
    } else if (param === "gcloud") {
        return "https://gcloud-api-seller.lettopia.com/graphql"
    } else if (param === "gcloudOnline") {
        return "https://gcloud-api-seller.lettopia.com/graphql"
    }
    return "https://gcloud-api-seller.lettopia.com/graphql"
}

function getJumpUrl (path) {
    const host = window.location.hostname;
    if (path[0] === "/") {
        path = path.slice(1);
    }
    if (host.includes("lettopia.cn")) {
        return `https://seller.lettopia.cn/#/${path}`;
    } else if (host.includes("seller-test.lettopia.com")) {
        return `https://gcloud-shopping-test.lettopia.com/#/${path}`
    } else if (host.includes("lettopia.com")) {
        return `https://www.lettopia.com/#/${path}`;
    } else if (host.includes("localhost") || host.includes("127.0.0.1")) {
        return `https://localhost:3000/#/${path}`;
    }
    return `https://www.lettopia.com/#/${path}`;
}
export {
    getBaseUrl,
    getJumpUrl
}