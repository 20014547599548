import { addLocaleData } from "react-intl";
import zh from "react-intl/locale-data/zh";
import en from "react-intl/locale-data/en";

import enMessages from "../i18n/en.json";
import zhMessages from "../i18n/zh.json";

const messages = {
  zh: zhMessages,
  en: enMessages
};

export default () => {
  addLocaleData([...zh, ...en]);
  return { messages };
};
