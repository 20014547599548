import lockr from 'lockr';

const TOKEN = 'wireless-token'

function setToken(token) {
  lockr.set(TOKEN, token)
}

function getToken() {
  return lockr.get(TOKEN, null)
}

function rmToken() {
  lockr.rm(TOKEN)
}

export {
  setToken, getToken, rmToken,
}
